<template>
  <div class="flex flex-col items-center justify-start group relative border border-lightgray rounded-lg shadow-sm p-2.5">
    <div class="relative">
      <div class="product-is-new absolute z-20 m-2" v-if="selectedVariant?.isNew">
        {{ $t('app.new') }}
      </div>

      <div class="group">

        <DiscountPercent :selected-variant="selectedVariant"/>

        <!-- TODO : Product Features -->
<!--        {{features}}-->
        <div class="relative flex">
          <div class="text-sm flex flex-col">
            <div class="p-1 " v-if="getAttributeItemImage('Mevsim')">
              <NuxtImg :src="getAttributeItemImage('Mevsim')" class="w-6 h-6" />
            </div>
            <div class="p-1" v-if="getAttributeItemImage('Araç Tipi')">
              <NuxtImg :src="getAttributeItemImage('Araç Tipi')" class="w-6 h-6" />
            </div>
          </div>
          <div class="relative overflow-hidden flex-1">
            <NuxtLinkLocale :to="selectedVariant?.url ? selectedVariant.url : '#'" class="block bg-white">
              <div class="flex absolute z-20 justify-center text-center items-center w-full h-full" v-if="!selectedVariant?.inStock">
                <span class="product-out-of-stock"><StockOutText /></span>
              </div>

              <div class="flex absolute z-20 justify-center text-center items-end w-full h-full" v-if="selectedVariant?.isFreeShipping">
                <span class="p-1.5 text-sm bg-lightgray-50 w-full rounded-b-lg flex items-center justify-center">
                  <BoltIcon class="h-5 w-5 mr-2 fill-primary"/>
                  <span>{{ $t('app.free-shipping') }}</span>
                </span>
              </div>

              <NuxtImg
                  v-if="selectedVariant?.thumbnail"
                  :src="getImage(selectedVariant.thumbnail)"
                  width="800"
                  height="600"
                  fit="outside"
                  loading="lazy"
                  :placeholder="[50, 50, 93, 3]"
                  :quality="90"
                  :alt="selectedVariant?.title"
                  :class="{'product-img-out-of-stock' : !selectedVariant?.inStock}"
                  class="z-1 rounded-lg object-cover object-top aspect-square mx-auto"
              />

              <NuxtImg
                  v-else
                  fit="outside"
                  alt=""
                  class="z-1 rounded-lg object-contain aspect-square"
                  width="300"
                  height="300"
                  src="/images/no-image.png"
              />
            </NuxtLinkLocale>

            <!-- Ürün Seçenekleri -->
            <SectionProductCardGroupOptions :product="product" />

          </div>

          <!-- Lastik Özellikleri -->
          <div class="absolute bg-white w-full bottom-0 z-10 text-sm flex flex-col justify-between items-center">
            <div class="pt-1" v-if="getBrandImage">
              <NuxtImg :src="getBrandImage" class="h-6 w-auto max-w-[130px] object-contain" />
            </div>

            <div class="flex items-center space-x-1.5 pt-1">
              <div class="flex items-center space-x-1" v-if="getAttributeImage('Etiket - Ses')">
                <NuxtImg :src="getAttributeImage('Etiket - Ses')" class="w-6 h-6" :alt="getAttributeText('Etiket - Ses')" />
                <span class="text-sm font-bold text-gray-600">{{getAttributeItemText('Etiket - Ses')}}</span>
              </div>
              <div class="flex items-center space-x-1" v-if="getAttributeImage('Etiket - Yakıt')">
                <NuxtImg :src="getAttributeImage('Etiket - Yakıt')" class="w-6 h-6" :alt="getAttributeText('Etiket - Yakıt')" />
                <span class="text-sm font-bold text-gray-600">{{getAttributeItemText('Etiket - Yakıt')}}</span>
              </div>
              <div class="flex items-center space-x-1" v-if="getAttributeImage('Yol Tutuş')">
                <NuxtImg :src="getAttributeImage('Yol Tutuş')" class="w-6 h-6" :alt="getAttributeText('Yol Tutuş')" />
                <span class="text-sm font-bold text-gray-600">{{getAttributeItemText('Yol Tutuş')}}</span>
              </div>
            </div>
          </div>

        </div>

        <!-- product.brandName için görsel eklenecek (brandImage) -->
        <div class="flex flex-col text-base text-primary">
          <div class="font-medium mt-2 line-clamp-2 min-h-[60px]">
            {{ product.brandName }} {{ selectedVariant?.title }}
          </div>

          <!-- Fiyat Bilgisi -->
          <SectionProductCardPriceView :variant="selectedVariant" />

          <p class="font-medium text-xs" v-if="getAttributeItemText('DOT')">Üretim : {{getAttributeItemText('DOT')}}</p>
        </div>

        <FormButton
            color="primary"
            outline
            size="sm"
            type="button"
            class="w-full mt-2"
            @click="AddToCartInProductCard"
            :loading="cartLoading"
            v-if="selectedVariant?.inStock"
        >
          <LucideShoppingCart class="h-4 w-4 mr-2"/>
          {{ $t('app.add-to-cart') }}
        </FormButton>

      </div>
    </div>
  </div>
</template>

<script setup>
import {BoltIcon} from "@heroicons/vue/24/outline";

import {collect} from "collect.js";
import DiscountPercent from "~/components/DiscountPercent.vue";
import PriceUpdateModeDescriptionForB2B from "~/components/PriceUpdateModeDescriptionForB2B.vue";
import {customRound} from "~/composables/useHelper";

const props = defineProps(['product'])

const storeConfig = useStoreConfig()
const { baseConfig, showRateNextToPrice } = storeToRefs(storeConfig)

const {
  selectedWarehouse,
  hidePriceForSoldOut,
  showListPrice
} = useListing()

const {$listen} = useNuxtApp()

const {currentPage} = usePagination()

const isLoading = ref(false)
const cartLoading = ref(false)

const selectedVariant = useState(`selectedVariant::${props.product.productId}::${props.product.productGroupValue}`);

const variants = await collect(props.product.variants);

const features = computed(() => props.product.features);

const isShowcaseMode = computed(() => unref(baseConfig)?.is_showcase_mode || unref(selectedVariant)?.isSaleable === 0)

const {addToBasket, addCartLoading, basketItems} = await useBasket()
const {isWholesaleUser, authModalIsVisible} = await useCustomer()
const {addWishlist} = useWishlist()
const {hidePricesForGuest} = useHelper()

const cartQuantity = ref(0)
const quantityStep = computed(() => selectedVariant.value?.primaryStockData?.quantityStep)

const AddToCartInProductCard = async () => {
  cartLoading.value = true
  await addToBasket(selectedVariant.value.variantId, 1, selectedVariant.value?.primaryStockData.rawCurrency, selectedVariant.value?.selectedWarehouse, false, false, false)
      .then(() => cartLoading.value = false)
}


const getAttributeImage = (attributeName) => {
  return features.value.filter((i) => i.attributeName === attributeName)[0]?.attributeImage
}

const getAttributeText = (attributeName) => {
  return features.value.filter((i) => i.attributeName === attributeName)[0]?.attributeName
}

const getAttributeItemImage = (attributeName) => {
  return features.value.filter((i) => i.attributeName === attributeName)[0]?.attributeItemImage
}

const getAttributeItemText = (attributeName) => {
  return features.value.filter((i) => i.attributeName === attributeName)[0]?.attributeItemName
}


const getBrandImage = computed(() => props.product?.brandImage)

/**
 * Eğer ürünler sepette ekli ise, ekli olan ürünlerin sayfa yenileme gibi işlemlerde hatırlanması için kontrol sağlıyoruz
 */

watch([basketItems, currentPage], async ([cartVal, currentPageVal]) => {
  const cartQty = await collect(cartVal).where('variant_id', '=', selectedVariant.value.variantId).pluck('qty').first()
  if (cartQty) {
    cartQuantity.value = cartQty
  }
})

</script>

<style>
.card-button {
  @apply w-full flex text-sm bg-gray-100 items-center justify-center rounded-lg font-bold py-2 px-2 text-primary fill-primary hover:text-white hover:fill-white;
}
</style>